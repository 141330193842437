import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PaiementService {
  constructor( private http: HttpClient ) { }

  
  rechercheFacture(data){
    return this.http.post< any[]>(environment.walletApi + '/encaissements/releve',data);
   }
    
  paiementFacture(data){
    return this.http.post< any[]>(environment.walletApi + '/encaissements',data);
   }
   ajouterWalletService(data) {
    return this.http.post<any[]>(environment.walletApi + '/clients/wallets/ajout', data);
  }
  addNewCardService(data) {
    return this.http.post<any[]>(environment.walletApi + '/clients/carte/ajout', data);
   }

   historique(data){
    return this.http.get< any[]>(environment.walletApi + '/transactions/clients?_page='+data);
   }
    pending(data){
        return this.http.get< any[]>(environment.walletApi + '/wallet/cashout/pending/?_page='+data);
    }
}
